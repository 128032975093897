import React from 'react'

// Components
import BlogOverview from 'components/flex/Blog/BlogOverview'
import BlogWorkAt from 'components/flex/Blog/BlogWorkAt'
import BlogQuoteSlider from './BlogQuoteSlider'

interface BlogShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BlogOverview
  location: any
}

interface BlogsProps {
  [key: string]: any
}

const BlogShell: React.FunctionComponent<BlogShellProps> = ({
  fields,
  location,
}) => {
  const blogs: BlogsProps = {
    standaard: BlogOverview,
    quoteslider: BlogQuoteSlider,
    werkenbij: BlogWorkAt,
  }

  if (!fields.styleType || !blogs[fields.styleType]) {
    return null
  }

  const Component = blogs[fields.styleType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BlogShell
