import React from 'react'
import { Link } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Images
import Clock from 'img/clock.inline.svg'
import Arrow from 'img/arrow.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const StyledBlogPreview = styled(motion.div)<{ layout: string }>`
  background-color: ${(props) => props.theme.color.face.greyLight};

  ${(props) =>
    props.layout === 'big' &&
    css`
      ${props.right
        ? css`
            grid-column: 3 / span 2;
            grid-row: 1 / span 2;
          `
        : css`
            grid-column: 1 / span 2;
            grid-row: 1 / span 2;
          `}

      @media screen and (max-width: 464px) {
        grid-column: 1;
        grid-row: 1;
      }
    `}

  position: relative;
  cursor: pointer;

  ${(props) =>
    props.layout === 'big'
      ? css`
          height: 540px;

          @media screen and (max-width: 576px) {
            height: 100%;
          }
        `
      : css`
          height: 260px;
        `}
`

const StyledGatsbyImage = styled(Plaatjie)`
  width: 100%;
  height: 100%;
`

const Fade = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  background: rgb(16, 11, 61);
  background: -moz-linear-gradient(
    0deg,
    rgba(16, 11, 61, 1) 0%,
    rgba(255, 255, 255, 0) 40%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(16, 11, 61, 1) 0%,
    rgba(255, 255, 255, 0) 40%
  );
  background: linear-gradient(
    0deg,
    rgba(16, 11, 61, 1) 0%,
    rgba(255, 255, 255, 0) 40%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#100b3d",endColorstr="#ffffff",GradientType=1);
`

const ArrowContainer = styled.div`
  position: absolute;
`

const ContentContainer = styled.div<{ layout: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0px 15px 60px 15px;

  & h2 {
    color: ${(props) => props.theme.color.text.light};
    font-weight: ${(props) => props.theme.font.weight.xl};
  }

  ${(props) =>
    props.layout === 'big'
      ? css`
          height: 150px;
          & h2 {
            font-size: ${props.theme.font.size[25]};
            line-height: 30px;
            padding-right: 50px;
          }

          ${ArrowContainer} {
            bottom: 20px;
            right: 20px;
          }

          @media screen and (max-width: 464px) {
            height: 130px;

            & h2 {
              font-size: ${props.theme.font.size[20]};
              line-height: 24px;
            }
          }
        `
      : css`
          height: 100px;
          & h2 {
            font-size: ${props.theme.font.size[18]};
            line-height: 22px;
          }

          ${ArrowContainer} {
            bottom: 15px;
            right: 15px;
          }
        `}
`

interface BlogPreviewProps {
  right?: boolean
  className?: string
  layout: string
  // eslint-disable-next-line
  post: GatsbyTypes.WpPost
}

const BlogPreview: React.FC<BlogPreviewProps> = ({
  className = '',
  layout,
  post,
  right = false,
}) => (
  <StyledBlogPreview
    whileHover={{ scale: 1.03, boxShadow: '0 0 20px rgba(0,0,0,0.3)' }}
    className={className}
    layout={layout}
    right={right}
  >
    <Link to={post.uri}>
      <div className="position-relative h-100 w-100">
        <StyledGatsbyImage alt="" image={post.blogPreview?.image} />
        <Fade />
        <ContentContainer layout={layout}>
          <ArrowContainer>
            <Arrow />
          </ArrowContainer>
          <div className="d-flex text-light font-size-14 align-items-center">
            <div className="mr-2">
              <Clock />
            </div>
            {post.blogPreview?.readtime}
          </div>
          <ParseContent content={post.blogPreview?.description} />
        </ContentContainer>
      </div>
    </Link>
  </StyledBlogPreview>
)

export default BlogPreview
