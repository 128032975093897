import React from 'react'
import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'
import BlogPreview from 'components/flex/Blog/BlogPreview'

const Content = styled(ParseContent)`
  & h3 {
    font-size: ${(props) => props.theme.font.size['40']};
    line-height: 45px;
    color: ${(props) => props.theme.color.face.main};

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size['23']};
      line-height: 30px;

      & br {
        display: none;
      }
    }
  }
`

const BlogContainer = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 464px) {
    grid-template-columns: 1fr;
  }
`

interface BlogWorkAtProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BlogOverview
}

const BlogWorkAt: React.FC<BlogWorkAtProps> = ({ fields }) => (
  <section id="dagelijks-werk">
    <div className="container">
      <div className="row py-4 py-lg-5 px-lg-2">
        <div className="col-12 px-lg-5">
          <Content content={fields?.descriptionWorkat} />
        </div>
      </div>
      <div className="row pb-5 m-0">
        {fields?.postCategory?.map(({ posts: { nodes } }, index) => (
          <BlogContainer key={index}>
            {nodes?.map((post: any, postindex: any) => (
              <BlogPreview
                right={false}
                post={post}
                layout={postindex === 0 ? `big` : `small-${postindex}`}
              />
            ))}
          </BlogContainer>
        ))}
      </div>
    </div>
  </section>
)

export default BlogWorkAt
