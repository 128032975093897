/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Slider
import Slider from 'react-slick'
import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'

// Images
import QuoteIcon from 'img/quote_icon.inline.svg'
import Left from 'img/slide-arrow-left-orange.png'
import Right from 'img/slide-arrow-right-orange.png'

// Components
import ParseContent from 'components/shared/ParseContent'
import BlogPreview from 'components/flex/Blog/BlogPreview'

// Third Party
import styled from 'styled-components'

const StyledGatsbyImage = styled(Plaatjie)`
  width: 100%;
  height: 100%;
`

const QuoteContainer = styled.div`
  background-color: ${(props) => props.theme.color.face.greyLight};
  width: 100%;

  & svg {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 185px;
    height: auto;
    z-index: 1;
  }
`

const Quote = styled(ParseContent)`
  width: 100%;
  padding: 20px 60px 20px 30px;
  position: relative;
  z-index: 2;

  & h2 {
    font-size: ${(props) => props.theme.font.size[30]};
    font-weight: ${(props) => props.theme.font.weight.xl};
    padding-bottom: 20px;
  }

  & blockquote {
    padding-left: 80px;
    font-style: italic;

    & p {
      font-size: ${(props) => props.theme.font.size[25]};
      line-height: 35px;

      @media (max-width: 991px) {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    padding: 20px 40px 20px 10px;

    & blockquote {
      padding-left: 30px;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 20px 15px 20px 15px;

    & blockquote {
      padding-left: 0px;
    }
  }
`

const BlogContainer = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 464px) {
    grid-template-columns: 1fr;
  }
`

const StyledSlider = styled(Slider)`
  & button.slick-prev {
    @media (max-width: 991px) {
      display: none !important;
    }
    @media (min-width: 992px) {
      left: 0px;
      z-index: 4;
    }
    background: url(${Left}) no-repeat;
    width: 78px;
    height: 78px;

    &::before {
      display: none;
    }
  }

  & button.slick-next {
    @media (max-width: 991px) {
      display: none !important;
    }
    @media (min-width: 992px) {
      right: 0px;
      z-index: 4;
    }
    background: url(${Right}) no-repeat;
    width: 78px;
    height: 78px;

    &::before {
      display: none;
    }
  }
`

interface BlogQuoteSliderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BlogOverview
}

const BlogQuoteSlider: React.FC<BlogQuoteSliderProps> = ({ fields }) => {
  const {
    posts: { edges: postsEdges },
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.QQQQQQuery>(graphql`
    query QQQQQ {
      posts: allWpPost(
        sort: { order: DESC, fields: date }
        filter: {
          categories: { nodes: { elemMatch: { databaseId: { ne: 1067 } } } }
        }
      ) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    accessibility: false,
    adaptiveHeight: true,
  }

  return (
    <div className="container py-lg-5">
      {postsEdges.slice(0, 5).length !== 0 && (
        <BlogContainer className="py-lg-5 py-4">
          {postsEdges?.slice(0, 5).map((post, index) => (
            <BlogPreview
              right={false}
              post={post.node}
              layout={index === 0 ? `big` : `small-${index}`}
            />
          ))}
        </BlogContainer>
      )}

      <StyledSlider {...settings} className="row position-relative">
        {fields?.quote?.map((item, index) => (
          <div className="d-flex flex-wrap" key={index}>
            <div className="col-lg-4 pr-lg-0">
              <StyledGatsbyImage alt="quote" image={item?.image} />
            </div>
            <div className="col-lg-8 pl-lg-0">
              <QuoteContainer className="h-100 position-relative d-flex align-items-center">
                <QuoteIcon />
                <Quote content={item?.description} />
              </QuoteContainer>
            </div>
          </div>
        ))}
      </StyledSlider>

      {postsEdges.slice(5, postsEdges.length).length !== 0 && (
        <BlogContainer className="py-lg-5 py-4">
          {postsEdges.slice(5, postsEdges.length).map((post, index) => (
            <BlogPreview
              right
              post={post.node}
              layout={index === 0 ? `big` : `small-${index}`}
            />
          ))}
        </BlogContainer>
      )}
    </div>
  )
}

export default BlogQuoteSlider
